export const SCOPES: { [key: string]: string } = {
    VIEW_OPEN_ID: 'openid',
    VIEW_PROFILE: 'profile',
    VIEW_EMAIL: 'email',
    DEVELOPMENT: 'development',
    VIEW_LEASE_OBJECTS: 'leaseObjects:view',
    CREATE_LEASE_OBJECTS: 'leaseObjects:create',
    EDIT_LEASE_OBJECTS: 'leaseObjects:edit',
    PUBLISH_LEASE_OBJECTS: 'leaseObjects:publish',
    FORCE_PUBLISH_LEASE_OBJECTS: 'leaseObjects:forcePublish',
    PUBLISH_OWN_LEASE_OBJECTS: 'ownLeaseObjects:publish',
    SWITCH_ELASTIC_INDEX: 'elastic:switch',
    VIEW_EXTERNAL_VALUE_MAPPINGS: 'externalValueMappings:view',
    EDIT_EXTERNAL_VALUE_MAPPINGS: 'externalValueMappings:edit',
    VIEW_UNPROCESSABLE_CONFIGURABLE_OBJECTS: 'unprocessableConfigurableObjects:view',
    VIEW_RESIDUAL_VALUES: 'residualValues:view',
    CREATE_RESIDUAL_VALUES: 'residualValues:create',
    EDIT_RESIDUAL_VALUES: 'residualValues:edit',
    VIEW_AUTOTELEX_CORRECTIONS: 'autotelexCorrections:view',
    CREATE_AUTOTELEX_CORRECTIONS: 'autotelexCorrections:create',
    EDIT_AUTOTELEX_CORRECTIONS: 'autotelexCorrections:edit',
    DELETE_AUTOTELEX_CORRECTIONS: 'autotelexCorrections:delete',
    VIEW_AUTOTELEX_TRADE_VALUE_CORRECTIONS: 'autotelexTradeValueCorrections:view',
    CREATE_AUTOTELEX_TRADE_VALUE_CORRECTIONS: 'autotelexTradeValueCorrections:create',
    EDIT_AUTOTELEX_TRADE_VALUE_CORRECTIONS: 'autotelexTradeValueCorrections:edit',
    VIEW_CORRECTED_RESIDUAL_VALUES: 'correctedResidualValues:view',
    VIEW_API_LOG: 'supplyRequestLog:view',
    VIEW_CUSTOMERS: 'customers:view',
    CREATE_CUSTOMERS: 'customers:create',
    EDIT_CUSTOMERS: 'customers:edit',
    VIEW_DISCOUNT_RULES: 'discountRules:view',
    CREATE_DISCOUNT_RULES: 'discountRules:create',
    EDIT_DISCOUNT_RULES: 'discountRules:edit',
    VIEW_CONTRIBUTION_MARGIN_RULES: 'contributionMarginRules:view',
    CREATE_CONTRIBUTION_MARGIN_RULES: 'contributionMarginRules:create',
    EDIT_CONTRIBUTION_MARGIN_RULES: 'contributionMarginRules:edit',
    DELETE_CONTRIBUTION_MARGIN_RULES: 'contributionMarginRules:delete',
    VIEW_CONTRIBUTION_MARGIN_SETS: 'contributionMarginSets:view',
    CREATE_CONTRIBUTION_MARGIN_SETS: 'contributionMarginSets:create',
    EDIT_CONTRIBUTION_MARGIN_SETS: 'contributionMarginSets:edit',
    JOBS_VIEW: 'jobs:view',
    DATASTORAGE_UPDATE: 'datastorage:update',
    VIEW_MAKES: 'makes:view',
    CREATE_MAKES: 'makes:create',
    EDIT_MAKES: 'makes:edit',
    VIEW_MODELS: 'models:view',
    CREATE_MODELS: 'models:create',
    EDIT_MODELS: 'models:edit',
    VIEW_FUEL_TYPES: 'fuelTypes:view',
    CREATE_FUEL_TYPES: 'fuelTypes:create',
    EDIT_FUEL_TYPES: 'fuelTypes:edit',
    VIEW_POWER_TRAINS: 'powerTrains:view',
    CREATE_POWER_TRAINS: 'powerTrains:create',
    EDIT_POWER_TRAINS: 'powerTrains:edit',
    VIEW_SEGMENTS: 'segments:view',
    CREATE_SEGMENTS: 'segments:create',
    EDIT_SEGMENTS: 'segments:edit',
    VIEW_BODY_TYPES: 'bodyTypes:view',
    CREATE_BODY_TYPES: 'bodyTypes:create',
    EDIT_BODY_TYPES: 'bodyTypes:edit',
    VIEW_TRANSMISSION_TYPES: 'transmissionTypes:view',
    CREATE_TRANSMISSION_TYPES: 'transmissionTypes:create',
    EDIT_TRANSMISSION_TYPES: 'transmissionTypes:edit',
    VIEW_RELEVANT_DISCOUNTS: 'relevantDiscounts:view',
    VIEW_INSURANCES: 'insurances:view',
    CREATE_INSURANCES: 'insurances:create',
    EDIT_INSURANCES: 'insurances:edit',
    DELETE_INSURANCES: 'insurances:delete',
    VIEW_SUPPLIERS: 'suppliers:view',
    CREATE_SUPPLIERS: 'suppliers:create',
    EDIT_SUPPLIERS: 'suppliers:edit',
    VIEW_RESIDUAL_VALUE_CORRECTION_MATRICES: 'residualValueCorrectionMatrices:view',
    CREATE_RESIDUAL_VALUE_CORRECTION_MATRICES: 'residualValueCorrectionMatrices:create',
    EDIT_RESIDUAL_VALUE_CORRECTION_MATRICES: 'residualValueCorrectionMatrices:edit',
    DELETE_RESIDUAL_VALUE_CORRECTION_MATRICES: 'residualValueCorrectionMatrices:delete',
    VIEW_FUEL_MAPPINGS: 'fuelMappings:view',
    CREATE_FUEL_MAPPINGS: 'fuelMappings:create',
    EDIT_FUEL_MAPPINGS: 'fuelMappings:edit',
    VIEW_SPEED_INDICES: 'speedIndices:view',
    CREATE_SPEED_INDICES: 'speedIndices:create',
    EDIT_SPEED_INDICES: 'speedIndices:edit',
    VIEW_TIRES: 'tires:view',
    CREATE_TIRES: 'tires:create',
    EDIT_TIRES: 'tires:edit',
    DELETE_TIRES: 'tires:delete',
    VIEW_BATTERY_TYPES: 'batteryTypes:view',
    CREATE_BATTERY_TYPES: 'batteryTypes:create',
    EDIT_BATTERY_TYPES: 'batteryTypes:edit',
    VIEW_FUEL_CARDS: 'fuelCards:view',
    CREATE_FUEL_CARDS: 'fuelCards:create',
    EDIT_FUEL_CARDS: 'fuelCards:edit',
    DELETE_FUEL_CARDS: 'fuelCards:delete',
    CREATE_INTERESTS: 'interests:create',
    EDIT_INTERESTS: 'interests:edit',
    VIEW_INTERESTS: 'interests:view',
    DELETE_INTERESTS: 'interests:delete',
    VIEW_DELIVERY_COSTS: 'deliveryCosts:view',
    CREATE_DELIVERY_COSTS: 'deliveryCosts:create',
    EDIT_DELIVERY_COSTS: 'deliveryCosts:edit',
    DELETE_DELIVERY_COSTS: 'deliveryCosts:delete',
    VIEW_MAINTENANCE_SEGMENTS: 'maintenanceSegments:view',
    CREATE_MAINTENANCE_SEGMENTS: 'maintenanceSegments:create',
    EDIT_MAINTENANCE_SEGMENTS: 'maintenanceSegments:edit',
    VIEW_MR_SCORE_FACTORS: 'mrScoreFactors:view',
    CREATE_MR_SCORE_FACTORS: 'mrScoreFactors:create',
    EDIT_MR_SCORE_FACTORS: 'mrScoreFactors:edit',
    VIEW_TIRE_SCORE_FACTORS: 'tireScoreFactors:view',
    CREATE_TIRE_SCORE_FACTORS: 'tireScoreFactors:create',
    EDIT_TIRE_SCORE_FACTORS: 'tireScoreFactors:edit',
    DELETE_TIRE_SCORE_FACTORS: 'tireScoreFactors:delete',
    VIEW_PAINTS: 'paints:view',
    CREATE_PAINTS: 'paints:create',
    EDIT_PAINTS: 'paints:edit',
    VIEW_COLORS: 'colors:view',
    CREATE_COLORS: 'colors:create',
    EDIT_COLORS: 'colors:edit',
    VIEW_ENRICHED_VEHICLE_OBJECT: 'enrichedVehicleObject:view',
    CREATE_EVENTS: 'events.create',
    VIEW_EVENTS: 'events:view',
    CREATE_INSURANCE_RULES: 'insuranceRules:create',
    VIEW_INSURANCE_RULES: 'insuranceRules:view',
    DELETE_INSURANCE_RULES: 'insuranceRules:delete',
    CREATE_JOBS: 'jobs:create',
    EDIT_JOBS: 'jobs:edit',
    VIEW_DATASTORAGE: 'datastorage:view',
    CREATE_CONFIGURABLE_OBJECTS: 'configurableObjects:create',
    EDIT_CONFIGURABLE_OBJECTS: 'configurableObjects:edit',
    VIEW_CONFIGURABLE_OBJECTS: 'configurableObjects:view',
    CREATE_OPTION_CATEGORIES: 'optionCategories:create',
    EDIT_OPTION_CATEGORIES: 'optionCategories:edit',
    VIEW_OPTION_CATEGORIES: 'optionCategories:view',
    CREATE_OPTIONS: 'options:create',
    EDIT_OPTIONS: 'options:edit',
    DELETE_OPTIONS: 'options:delete',
    VIEW_OPTIONS: 'options:view',
    MANAGE_NON_CUSTOM_OPTIONS: 'options:manageNonCustom',
    CREATE_STOCK_OPTIONS: 'stockOptions:create',
    EDIT_STOCK_OPTIONS: 'stockOptions:edit',
    DELETE_STOCK_OPTIONS: 'stockOptions:delete',
    VIEW_STOCK_OPTIONS: 'stockOptions:view',
    MANAGE_NON_CUSTOM_STOCK_OPTIONS: 'stockOptions:manageNonCustom',
    CREATE_RESIDUAL_VALUE_CORRECTIONS: 'residualValueCorrections:create',
    EDIT_RESIDUAL_VALUE_CORRECTIONS: 'residualValueCorrections:edit',
    VIEW_RESIDUAL_VALUE_CORRECTIONS: 'residualValueCorrections:view',
    DELETE_RESIDUAL_VALUE_CORRECTIONS: 'residualValueCorrections:delete',
    EDIT_TRIMS: 'trims:edit',
    CREATE_TRIMS: 'trims:create',
    VIEW_TRIMS: 'trims:view',
    FACADE_CALCULATE: 'facade:calculate',
    CREATE_STOCK_OBJECTS: 'stockObjects:create',
    EDIT_STOCK_OBJECTS: 'stockObjects:edit',
    DELETE_STOCK_OBJECTS: 'stockObjects:delete',
    VIEW_STOCK_OBJECTS: 'stockObjects:view',
    VIEW_MR_PRICE_CORRECTION_MATRICES: 'mrPriceCorrectionMatrices:view',
    CREATE_MR_PRICE_CORRECTION_MATRICES: 'mrPriceCorrectionMatrices:create',
    EDIT_MR_PRICE_CORRECTION_MATRICES: 'mrPriceCorrectionMatrices:edit',
    DELETE_MR_PRICE_CORRECTION_MATRICES: 'mrPriceCorrectionMatrices:delete',
    CREATE_MR_PRICE_CORRECTIONS: 'mrPriceCorrections:create',
    EDIT_MR_PRICE_CORRECTIONS: 'mrPriceCorrections:edit',
    VIEW_MR_PRICE_CORRECTIONS: 'mrPriceCorrections:view',
    DELETE_MR_PRICE_CORRECTIONS: 'mrPriceCorrections:delete',
    CREATE_FUEL_PRICES: 'fuelPrices:create',
    EDIT_FUEL_PRICES: 'fuelPrices:edit',
    VIEW_FUEL_PRICES: 'fuelPrices:view',
    DELETE_FUEL_PRICES: 'fuelPrices:delete',
    CREATE_ROAD_TAXES: 'roadTaxes:create',
    EDIT_ROAD_TAXES: 'roadTaxes:edit',
    VIEW_ROAD_TAXES: 'roadTaxes:view',
    DELETE_ROAD_TAXES: 'roadTaxes:delete',
    CREATE_ROAD_TAX_AMOUNT: 'roadTaxAmount:create',
    EDIT_ROAD_TAX_AMOUNT: 'roadTaxAmount:edit',
    VIEW_ROAD_TAX_AMOUNT: 'roadTaxAmount:view',
    DELETE_ROAD_TAX_AMOUNT: 'roadTaxAmount:delete',
    VIEW_ADDITIONAL_SERVICES: 'additionalServices:view',
    CREATE_ADDITIONAL_SERVICES: 'additionalServices:create',
    EDIT_ADDITIONAL_SERVICES: 'additionalServices:edit',
    DELETE_ADDITIONAL_SERVICES: 'additionalServices:delete',
    VIEW_ADDITIONAL_INSURANCES: 'additionalInsurances:view',
    CREATE_ADDITIONAL_INSURANCES: 'additionalInsurances:create',
    EDIT_ADDITIONAL_INSURANCES: 'additionalInsurances:edit',
    DELETE_ADDITIONAL_INSURANCES: 'additionalInsurances:delete',
    VIEW_SERVICE_LIFE_TIRE_WIDTHS: 'serviceLifeTireWidths:view',
    CREATE_SERVICE_LIFE_TIRE_WIDTHS: 'serviceLifeTireWidths:create',
    EDIT_SERVICE_LIFE_TIRE_WIDTHS: 'serviceLifeTireWidths:edit',
    DELETE_SERVICE_LIFE_TIRE_WIDTHS: 'serviceLifeTireWidths:delete',
    VIEW_SERVICE_LIFE_RIM_SIZE_FACTORS: 'serviceLifeRimSizeFactors:view',
    CREATE_SERVICE_LIFE_RIM_SIZE_FACTORS: 'serviceLifeRimSizeFactors:create',
    EDIT_SERVICE_LIFE_RIM_SIZE_FACTORS: 'serviceLifeRimSizeFactors:edit',
    DELETE_SERVICE_LIFE_RIM_SIZE_FACTORS: 'serviceLifeRimSizeFactors:delete',
    VIEW_SERVICE_LIFE_VEHICLE_TYPE_TIRE_TYPE_FACTORS: 'serviceLifeVehicleTypeTireTypeFactors:view',
    CREATE_SERVICE_LIFE_VEHICLE_TYPE_TIRE_TYPE_FACTORS: 'serviceLifeVehicleTypeTireTypeFactors:create',
    EDIT_SERVICE_LIFE_VEHICLE_TYPE_TIRE_TYPE_FACTORS: 'serviceLifeVehicleTypeTireTypeFactors:edit',
    DELETE_SERVICE_LIFE_VEHICLE_TYPE_TIRE_TYPE_FACTORS: 'serviceLifeVehicleTypeTireTypeFactors:delete',
    VIEW_CHANNELS: 'channels:view',
    CREATE_CHANNELS: 'channels:create',
    EDIT_CHANNELS: 'channels:edit',
    DELETE_CHANNELS: 'channels:delete',
    VIEW_PARAMETER_SETS: 'parameterSets:view',
    CREATE_PARAMETER_SETS: 'parameterSets:create',
    EDIT_PARAMETER_SETS: 'parameterSets:edit',
    DELETE_PARAMETER_SETS: 'parameterSets:delete',
    CREATE_ROAD_TAXES_BE_BRUSSELS: 'roadTaxesBEBrussels:create',
    EDIT_ROAD_TAXES_BE_BRUSSELS: 'roadTaxesBEBrussels:edit',
    VIEW_ROAD_TAXES_BE_BRUSSELS: 'roadTaxesBEBrussels:view',
    DELETE_ROAD_TAXES_BE_BRUSSELS: 'roadTaxesBEBrussels:delete',
    CREATE_ROAD_TAXES_BE_BRUSSELS_LPG_ADDITIONS: 'roadTaxesBEBrusselsLPGAdditions:create',
    EDIT_ROAD_TAXES_BE_BRUSSELS_LPG_ADDITIONS: 'roadTaxesBEBrusselsLPGAdditions:edit',
    VIEW_ROAD_TAXES_BE_BRUSSELS_LPG_ADDITIONS: 'roadTaxesBEBrusselsLPGAdditions:view',
    DELETE_ROAD_TAXES_BE_BRUSSELS_LPG_ADDITIONS: 'roadTaxesBEBrusselsLPGAdditions:delete',
    VIEW_BPM_DEPRECIATIONS: 'bpmDepreciations:view', // ROLE_BPMDEPRECIATIONS_VIEW
    CREATE_BPM_DEPRECIATIONS: 'bpmDepreciations:create', // ROLE_BPMDEPRECIATIONS_CREATE
    EDIT_BPM_DEPRECIATIONS: 'bpmDepreciations:edit', // ROLE_BPMDEPRECIATIONS_EDIT
    DELETE_BPM_DEPRECIATIONS: 'bpmDepreciations:delete', // ROLE_BPMDEPRECIATIONS_DELETE
    VIEW_BPM_DIESEL_SURCHARGES: 'bpmDieselSurcharges:view', // ROLE_BPMDIESELSURCHARGES_VIEW
    CREATE_BPM_DIESEL_SURCHARGES: 'bpmDieselSurcharges:create', // ROLE_BPMDIESELSURCHARGES_CREATE
    EDIT_BPM_DIESEL_SURCHARGES: 'bpmDieselSurcharges:edit', // ROLE_BPMDIESELSURCHARGES_EDIT
    DELETE_BPM_DIESEL_SURCHARGES: 'bpmDieselSurcharges:delete', // ROLE_BPMDIESELSURCHARGES_DELETE
    VIEW_BPM_PASSENGER_VEHICLES: 'bpmPassengerVehicles:view', // ROLE_BPMPASSENGERVEHICLES_VIEW
    CREATE_BPM_PASSENGER_VEHICLES: 'bpmPassengerVehicles:create', // ROLE_BPMPASSENGERVEHICLES_CREATE
    EDIT_BPM_PASSENGER_VEHICLES: 'bpmPassengerVehicles:edit', // ROLE_BPMPASSENGERVEHICLES_EDIT
    DELETE_BPM_PASSENGER_VEHICLES: 'bpmPassengerVehicles:delete', // ROLE_BPMPASSENGERVEHICLES_DELETE
    VIEW_MORE_FEWERS: 'moreFewers:view',
    CREATE_MORE_FEWERS: 'moreFewers:create',
    DELETE_MORE_FEWERS: 'moreFewers:delete',
    VIEW_REPLACEMENT_DESCRIPTIONS: 'replacementDescriptions:view',
    CREATE_REPLACEMENT_DESCRIPTIONS: 'replacementDescriptions:create',
    EDIT_REPLACEMENT_DESCRIPTIONS: 'replacementDescriptions:edit',
    DELETE_REPLACEMENT_DESCRIPTIONS: 'replacementDescriptions:delete',
    VIEW_REPLACEMENT_PRODUCT_FACTORS: 'replacementProductFactors:view',
    CREATE_REPLACEMENT_PRODUCT_FACTORS: 'replacementProductFactors:create',
    EDIT_REPLACEMENT_PRODUCT_FACTORS: 'replacementProductFactors:edit',
    DELETE_REPLACEMENT_PRODUCT_FACTORS: 'replacementProductFactors:delete',
    VIEW_REPLACEMENT_MILEAGE_TO_DAYS: 'replacementMileageToDays:view',
    CREATE_REPLACEMENT_MILEAGE_TO_DAYS: 'replacementMileageToDays:create',
    EDIT_REPLACEMENT_MILEAGE_TO_DAYS: 'replacementMileageToDays:edit',
    DELETE_REPLACEMENT_MILEAGE_TO_DAYS: 'replacementMileageToDays:delete',
    VIEW_CAR_SELECTIONS: 'carSelections:view',
    VIEW_MASTER_TRANSLATIONS: 'masterTranslations:view',
    CREATE_MASTER_TRANSLATIONS: 'masterTranslations:create',
    EDIT_MASTER_TRANSLATIONS: 'masterTranslations:edit',
}
